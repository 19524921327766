import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../../components/shared-layout";
import useStores from "../../../hooks/use-stores";
import VmTable from "../../../components/shared-table";
import VmButton from "../../../components/shared-button";
import { ApiDataListType, ApiDataResponseListLengthCheck, CRUDOperation } from "../../../constants/options";
import Breadcrumb from "../../../components/shared-breadcrumb";
import VmModal from "../../../components/shared-modal";
import { navigate } from "gatsby";
import { t } from "i18next";
import ContentLayout from "../../../components/shared-content-layout";
import { INLINE_SELECTED, INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL } from "../../../constants/style";

const QuestionOverviewIndexPage = observer(() => {
  const { rootStore, academicStore, questionStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // Determine is update or create
  const [currentSubject, setCurrentSubject] = useState<number>(0);
  const [currentQueCat, setCurrentQueCat] = useState<number>(0);
  const [currentKnowledgePt, setCurrentKnowledgePt] = useState<number>(0);

  const [targetQuestion, setTargetQuestion] = useState<any>();
  const [detail, setDetail] = useState<string>("");
  const [mark, setMark] = useState<string>("0");
  const [questionCatId, setQuestionCatId] = useState<string>("0");
  const [knowledgeId, setKnowledgeId] = useState<string>("0");
  const [difficulty, setDifficulty] = useState<string>("0");
  const [typeId, setTypeId] = useState<string>("0");
  const [searchSubjectKey, setSearchSubjectKey] = useState<string>("");
  const onChangeQuestionType = (event: SelectChangeEvent) => {
    setTypeId(event.target.value as string);
  };
  const onChangeKnowledegePoint = (event: SelectChangeEvent) => {
    setKnowledgeId(event.target.value as string);
  }
  const onChangeQuestionCat = (event: SelectChangeEvent) => {
    setQuestionCatId(event.target.value as string);
  };
  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.SUBJECT) != academicStore.subjectList.data.length) {
      academicStore.getSubjectList(0, 0)
        .then((res) => onToggleSubjectList(res.data[0].id));
    } else {
      onToggleSubjectList(academicStore.subjectList.data[0].id)
    }

    if (ApiDataResponseListLengthCheck(ApiDataListType.QUESTION_TYPE) == 0) {
      questionStore.getQuestionType();
    }
  }, []);

  const onToggleSubjectList = (id: number) => {
    questionStore.getQuestionListBySubjectId(id)
      .then((res) => {
        setCurrentQueCat(
          currentQueCat != 0 ? currentQueCat :
            res[0].questionCategories[0] ? res[0].questionCategories[0].id : 0
        );
        setCurrentKnowledgePt(
          currentKnowledgePt != 0 ? currentKnowledgePt
            : res[0].questionCategories.length > 0 &&
              res[0].questionCategories[0].questionKnowledgePoints.length > 0 ?
              res[0].questionCategories[0].questionKnowledgePoints[0].id : 0
        );
      });
    setCurrentSubject(id);
  }

  const onToggleQuestionCat = (id: number) => {
    let target = questionStore.questionCatList.data.find(qc => qc.id == id);
    setCurrentQueCat(target.id);
    setCurrentKnowledgePt(target.questionKnowledgePoints[0] ? target.questionKnowledgePoints[0].id : 0);
  }

  const onToggleKnowledegePt = (id: number) => {
    let target = questionStore.questionCatList.data.find(qc => qc.id == currentQueCat)
      .questionKnowledgePoints.find(qkp => qkp.id == id);
    setCurrentKnowledgePt(target.id);
  }

  const onActionQuestion = () => {
    let req: any = {
      id: 0,
      detail,
      // mark,
      knowledgePointId: knowledgeId,
      difficulty,
      type: typeId
    }
    if (targetQuestion) {
      req.id = targetQuestion.id;
      questionStore.updateQuestion(req).then(() => {
        rootStore.notify(t('EXAM_QUESTIONS') + t('UPDATED_B'), 'success');
        onToggleSubjectList(academicStore.subjectList[0].id);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
    } else {
      questionStore.createQuestion(req).then(() => {
        rootStore.notify(t('EXAM_QUESTIONS') + t('CREATED_B'), 'success')
        onToggleSubjectList(academicStore.subjectList[0].id);
        handleCloseModal();
      }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
    }
  }

  const handleShowModal = (id?: any) => {
    if (id) {
      let target = questionStore.questionCatList.find(q => q.id == currentQueCat)
        .questionKnowledgePoints.find(qkp => qkp.id == currentKnowledgePt)
        .questionLists.find(q => q.id == id);
      setTargetQuestion(target);
      setDetail(target.detail);
      setMark(target.mark);
      setDifficulty(target.difficulty);
      setQuestionCatId(currentQueCat.toString());
      setKnowledgeId(target.knowledgePointId);
      setTypeId(target.type);
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setTargetQuestion(null);
    setDetail("");
    setMark("");
    setQuestionCatId("0");
    setKnowledgeId("0");
    setDifficulty("0");
    setTypeId("0");
  }

  const handleDeleteModal = (id: number) => {
    let target = questionStore.questionList.find(s => s.id == currentSubject)
      .questionCategories.find(qc => qc.id == currentQueCat)
      .questionKnowledgePoints.find(qkp => qkp.id == currentKnowledgePt)
      .questionLists.find(q => q.id == id);
    setTargetQuestion(target);
    setShowDeleteModal(true);
  }

  return (
    <ContentLayout
      pageName={t('QUESTION') + t('OVERVIEW_B')}
      pageHeading={t('QUESTION') + t('OVERVIEW_B')}
      breadCrumb={[
        { label: t('QUESTION') + t('SETTING_B'), to: "/question/overview" },
        { label: t('OVERVIEW'), to: "" }
      ]}
      disabledButton={questionStore.questionCatList.data.length == 0}
      buttonLabel={`${t('ADD_A') + t('QUESTION')} ${questionStore.questionCatList.data.length == 0 ? `(${t('NO_QUESTION_CAT_FOUND')})` : ''}`}
      onClickAction={() => handleShowModal()}
    >
      <div style={ITEM_PERFECT_INLINED}>
        <TextField
          value={searchSubjectKey}
          label={t('SEARCH_SUBJECT')}
          variant="outlined"
          sx={{ width: 300, background: 'white', marginRight: 2 }}
          onChange={(value) => setSearchSubjectKey(value.target.value)}
          size="small"
        />
        <Typography>{t('SUBJECT_COUNT')}: {academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
          sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "").length}</Typography>
      </div>

      <div className="grid grid-cols-3 gap-4 py-4">
        <div className="max-h-80 overflow-auto">
          {academicStore.subjectList.data.length > 0 &&
            academicStore.subjectList.data.filter(sub => searchSubjectKey !== "" ?
              sub.subjectCode.toLowerCase().includes(searchSubjectKey.toLowerCase()) : sub.subjectCode !== "")
              .map((sub: any, index: number) => (
                <Box key={`subject_select_${index}`}>
                  <button
                    type="button"
                    className={`${currentSubject == sub.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                    onClick={() => onToggleSubjectList(sub.id)}
                  >
                    {sub.subjectCode}
                  </button>
                </Box>
              ))}
        </div>

        <div className="max-h-80 overflow-auto">
          {questionStore.questionCatList.data.length > 0 && currentQueCat != 0 ?
            questionStore.questionCatList.data.map((qc: any, index: number) => (
              <Box key={`question_categories_select_${index}`}>
                <button
                  type="button"
                  className={`${currentQueCat == qc.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                  onClick={() => onToggleQuestionCat(qc.id)}
                >
                  {qc.categoryCode}
                </button>
              </Box>
            ))
            : <Typography textAlign={"center"} className="col-span-8">{t('NO_QUESTION_CAT_FOUND')}</Typography>
          }
        </div>
        <div className="max-h-80 overflow-auto">
          {questionStore.questionCatList.data.length > 0 && currentKnowledgePt != 0 ?
            questionStore.questionCatList.data.filter(qc => qc.id == currentQueCat).map((qc: any) => (
              qc.questionKnowledgePoints.map((qkp: any, index: number) => (
                <Box key={`question_knowledege_pt_select_${index}`}>
                  <button
                    type="button"
                    className={`${currentKnowledgePt == qkp.id ? INLINE_SELECTED_OPTION : INLINE_SELECT_OPTION}`}
                    onClick={() => onToggleKnowledegePt(qkp.id)}
                  >
                    {qkp.code}
                  </button>
                </Box>
              ))
            ))
            : <Typography textAlign={"center"} className="col-span-8">{t('NO_KNW_PT_FOUND')}</Typography>
          }
        </div>
      </div>
      
        <VmTable
          loading={academicStore.loading}
          page={0}
          thead={["ID", t('DETAIL'), t('DIFFICULTY'), t('TYPE'), t('ACTION')]}
          tbody={questionStore.questionList.length > 0 &&
            // if there are no question categories and knowledege pt selected, the table data hides
            currentQueCat != 0 && currentKnowledgePt != 0 && questionStore.questionCatList.data.find(q => q.id == currentQueCat) &&
            questionStore.questionCatList.data.find(q => q.id == currentQueCat)
              .questionKnowledgePoints.find(qkp => qkp.id == currentKnowledgePt) &&
            questionStore.questionCatList.data.find(q => q.id == currentQueCat)
              .questionKnowledgePoints.find(qkp => qkp.id == currentKnowledgePt)
              .questionLists.map((question: any, index: number) => (
                <>
                  <Box marginY={1} />
                  <tr key={`question_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{question.detail.length > 50 ? question.detail.substring(0, 50) + "..." : question.detail}</td>
                    <td className={TD_NORMAL}>{question.difficulty}</td>
                    <td className={TD_NORMAL}>
                      {questionStore.questionTypeList.length > 0 && questionStore.questionTypeList.find(q => q.id == question.type).typeCode}
                    </td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowModal(question.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ marginRight: 2 }}
                        onClick={() => navigate("/question/overview/question-option",
                          { state: { subjectId: currentSubject, categoryId: currentQueCat, knowledgeId: currentKnowledgePt, questionId: question.id } })}
                      >
                        {t('VIEW_OPTIONS')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(question.id)}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
        />

      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetQuestion ? t('UPDATE_A') + t('QUESTION') : t('ADD_A') + t('QUESTION')}
        buttonLabel={targetQuestion ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetQuestion ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={onActionQuestion}
        disabledCondition={questionStore.questionTypeList.length < 1 || academicStore.subjectList.length < 1 ||
          detail === ""}
      >
        <FormControl fullWidth>
          <InputLabel>{t('CATEGORY')}</InputLabel>
          {
            questionStore.questionCatList.data.length > 0 &&
            <Select
              label={t('CATEGORY')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={questionCatId}
              onChange={onChangeQuestionCat}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {questionStore.questionCatList.data.map((question: any, index: number) => (
                <MenuItem key={`question_knowledge_point_select_${index}`} value={question.id}>
                  {question.categoryCode}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t('KNOWLEDEGE_POINT')}</InputLabel>
          {
            questionStore.questionCatList.data.length > 0 &&
            <Select
              label={t('KNOWLEDEGE_POINT')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={knowledgeId}
              disabled={questionCatId === "0"}
              onChange={onChangeKnowledegePoint}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {questionCatId !== "0" &&
                questionStore.questionCatList.data.find(qc => qc.id == questionCatId).
                  questionKnowledgePoints.map((question: any, index: number) => (
                    <MenuItem key={`question_cat_select_${index}`} value={question.id}>
                      {question.code}
                    </MenuItem>
                  ))}
            </Select>
          }
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t('QUESTION_TYPE')}</InputLabel>
          {
            questionStore.questionTypeList.length > 0 &&
            <Select
              label={t('QUESTION_TYPE')}
              sx={{ width: '100%' }}
              value={typeId}
              onChange={onChangeQuestionType}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {questionStore.questionTypeList.map((question: any, index: number) => (
                <MenuItem key={`question_type_select_${index}`} value={question.id}>
                  {question.typeCode}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl>
        <div className="grid grid-cols-2 gap-4">
          {/* <TextField
              value={mark}
              type="number"
              label="Mark"
              variant="outlined"
              sx={{ width: '100%', marginTop: 2 }}
              onChange={(value) => setMark(value.target.value)}
            /> */}
          <TextField
            value={difficulty}
            type="number"
            label={t('DIFFICULTY')}
            variant="outlined"
            sx={{ width: '100%', marginTop: 2 }}
            onChange={(value) => setDifficulty(value.target.value)}
          />
        </div>

        <TextField
          value={detail}
          label={t('DETAIL')}
          variant="outlined"
          sx={{ width: '100%', marginTop: 2 }}
          onChange={(value) => setDetail(value.target.value)}
          multiline
          rows={4}
        />
      </VmModal>

      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => {
          questionStore.deleteQuestion(targetQuestion.id)
            .then(() => {
              rootStore.notify(t('EXAM_QUESTIONS') + t('DELETED_B'), 'success')
              onToggleSubjectList(academicStore.subjectList[0].id);
              handleCloseModal();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'))
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>{t('DELETE_CHECK')}?</Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_QUESTION')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default QuestionOverviewIndexPage;
